//@ts-check
import React, { useEffect, useState } from "react";
import { Link, navigate } from "gatsby";
import API from "../../utils/API";
import { Modal } from "antd";

const PricingSignedin = () => {
  const [userDetails, setUserDetails] = useState({
    emailAddress: "",
    membershipLevel: "free",
    requestsRemaining: "",
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [levelChosen, setLevelChosen] = useState("");

  const showModal = level => {
    if (userDetails?.membershipLevel === "free") {
      onOptionSelected(level);
      return;
    }

    setLevelChosen(level);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    onOptionSelected(levelChosen);
    setIsModalOpen(false);
    setLevelChosen("");
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setLevelChosen("");
  };

  const getUser = async () => {
    const user = await API.get({ path: "mydetails" });

    setUserDetails(user);
  };

  useEffect(() => {
    getUser();
  }, []);

  const onOptionSelected = async membershipLevel => {
    console.log({ membershipLevel });

    try {
      const res = await API.post({
        path: "create-checkout-session",
        data: {
          membershipLevel,
        },
      });
      console.log({ checkoutUrl: res.redirectUrl });

      if (res.redirectUrl) {
        window.location.replace(res.redirectUrl);
      }
    } catch (error) {
      console.log("error making request to check session api");
      console.log(error);
    }
  };

  const ChangeSubscriptionModal = () => {
    const current =
      userDetails?.membershipLevel?.[0].toUpperCase() +
      userDetails?.membershipLevel.slice(1);
    const newLevel = levelChosen?.[0]?.toUpperCase() + levelChosen.slice(1);

    return (
      <Modal
        title="Changing Your Membership"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>
          Your current subscription is <b>{current}</b>
        </p>
        <p>
          You've selected to change to <b>{newLevel}</b>
        </p>
        <p>
          Confirming this will change your subscription and, if you're
          upgrading, will charge your payment card.
        </p>
      </Modal>
    );
  };

  return (
    <section className="pricing-area bg-pattern pt-100 pb-70 ">
      <div className="container">
        <div className="section-title">
          <h2>Start Growing on LinkedIn Today</h2>
          <p></p>
        </div>
        <ChangeSubscriptionModal />
        <div className="row">
          <div className="col-lg-3 col-md-6">
            <div className="single-pricing-box">
              <div className="pricing-header">
                <h3>Free</h3>
              </div>

              <div className="price">
                <sup>$</sup> 0 <sub>/ Per month</sub>
              </div>

              <ul className="pricing-features">
                <li>
                  <i className="bx bxs-badge-check"></i> AI Comment Generator
                </li>
                <li>
                  <i className="bx bxs-badge-check"></i> 5 Comments / week
                </li>
                <li>
                  <i className="bx bxs-badge-check"></i> Set Comment Tone
                </li>
                <li>
                  <i className="bx bxs-badge-check"></i> Customise Comment Type
                </li>
                <li>
                  <i className="bx bxs-x-circle red"></i> Use with Multiple
                  LinkedIn Accounts
                </li>
              </ul>

              <div className="btn-box">
                {userDetails?.membershipLevel === "free" ? (
                  <Link to="my-account" className="default-btn">
                    <i className="flaticon-tick"></i>
                    Your Current Subscription
                    <span></span>
                  </Link>
                ) : (
                  <Link
                    to={process.env.STRIPE_CUSTOMER_PORTAL || ""}
                    className="default-btn"
                  >
                    <i className="flaticon-tick"></i>
                    {userDetails?.membershipLevel === "free"
                      ? "Your Current Subscription"
                      : "Downgrade to Free"}
                    <span></span>
                  </Link>
                )}
              </div>
            </div>
          </div>

          {/*
            <div className="col-lg-3 col-md-6">
            <div className="single-pricing-box">
              <div className="pricing-header">
                <h3>Basic</h3>
              </div>

              <div className="price">
                <sup>$</sup> 12 <sub>/ Per month</sub>
              </div>

              <ul className="pricing-features">
                <li>
                  <i className="bx bxs-badge-check"></i> Comment Reminder Tool
                </li>
                <li>
                  <i className="bx bxs-badge-check"></i> AI Comment Generator
                </li>
                <li>
                  <i className="bx bxs-badge-check"></i> 150 Comments / Month
                </li>
                <li>
                  <i className="bx bxs-badge-check"></i> 5 Comments / Day
                </li>
                <li>
                  <i className="bx bxs-x-circle red"></i> Set Comment Tone
                </li>
                <li>
                  <i className="bx bxs-x-circle red"></i> Use with Multiple
                  LinkedIn Accounts
                </li>
              </ul>

              <div className="btn-box">
                {userDetails?.membershipLevel === "basic" ? (
                  <Link to="/my-account" className="default-btn">
                    <i className="flaticon-tick"></i>Your Current Subscription
                    <span></span>
                  </Link>
                ) : (
                  <button
                    onClick={() => showModal("basic")}
                    className="default-btn"
                  >
                    <i className="flaticon-tick"></i>
                    Select this Plan
                  </button>
                )}
              </div>
            </div>
          </div>
          */}

          <div className="col-lg-3 col-md-6 offset-lg-0 offset-md-3">
            <div className="single-pricing-box">
              <div className="pricing-header">
                <h3>Growth</h3>
              </div>

              <div className="price">
                <sup>$</sup> 30 <sub> / Per month</sub>
              </div>

              <ul className="pricing-features">
                <li>
                  <i className="bx bxs-badge-check"></i> AI Comment Generator
                </li>
                <li>
                  <i className="bx bxs-badge-check"></i> 750 Comments / Month
                </li>
                <li>
                  <i className="bx bxs-badge-check"></i> 25 Comments / Day
                </li>
                <li>
                  <i className="bx bxs-badge-check"></i> Set Comment Tone
                </li>
                <li>
                  <i className="bx bxs-badge-check"></i> Customise Comment Type
                </li>
                <li>
                  <i className="bx bxs-x-circle red"></i> Use with Multiple
                  LinkedIn Accounts
                </li>
              </ul>

              <div className="btn-box">
                {userDetails?.membershipLevel === "growth" ? (
                  <Link to="/my-account" className="default-btn">
                    <i className="flaticon-tick"></i>Your Current Subscription
                    <span></span>
                  </Link>
                ) : (
                  <button
                    onClick={() => showModal("growth")}
                    className="default-btn"
                  >
                    <i className="flaticon-tick"></i>
                    Select this Plan
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 offset-lg-0 offset-md-3">
            <div className="single-pricing-box">
              <div className="pricing-header">
                <h3>Pro</h3>
              </div>

              <div className="price">
                <sup>$</sup> 99 <sub> / Per month</sub>
              </div>

              <ul className="pricing-features">
                <li>
                  <i className="bx bxs-badge-check"></i> Comment Reminder Tool
                </li>
                <li>
                  <i className="bx bxs-badge-check"></i> AI Comment Generator
                </li>
                <li>
                  <i className="bx bxs-badge-check"></i> 3000 Comments / Month
                </li>
                <li>
                  <i className="bx bxs-badge-check"></i> 100 Comments / Day
                </li>
                <li>
                  <i className="bx bxs-badge-check"></i> Set Comment Tone
                </li>
                <li>
                  <i className="bx bxs-badge-check"></i> Customise Comment Type
                </li>
                <li>
                  <i className="bx bxs-badge-check"></i> Use with Multiple
                  LinkedIn Accounts
                </li>
              </ul>

              <div className="btn-box">
                {userDetails?.membershipLevel === "pro" ? (
                  <Link to="/my-account" className="default-btn">
                    <i className="flaticon-tick"></i>Your Current Subscription
                    <span></span>
                  </Link>
                ) : (
                  <button
                    onClick={() => showModal("pro")}
                    className="default-btn"
                  >
                    <i className="flaticon-tick"></i>
                    Select this Plan
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PricingSignedin;
