import React from "react";
import { Link } from "gatsby";

const Pricing = () => {
  return (
    <section
      id="pricing-area"
      className="pricing-area bg-pattern pt-100 pb-70 "
    >
      <div className="container">
        <div className="section-title">
          <h2>Start Growing on LinkedIn Today</h2>
          <p></p>
        </div>

        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="single-pricing-box">
              <div className="pricing-header">
                <h3>Free</h3>
              </div>

              <div className="price">
                <sup>$</sup> 0 <sub>/ Per month</sub>
              </div>

              <ul className="pricing-features">
                <li>
                  <i className="bx bxs-badge-check"></i> AI Comment Generator
                </li>
                <li>
                  <i className="bx bxs-badge-check"></i> 5 Comments / week
                </li>
                <li>
                  <i className="bx bxs-badge-check"></i> Set Comment Tone
                </li>
                <li>
                  <i className="bx bxs-badge-check"></i> Customise Comment Type
                </li>
                <li>
                  <i className="bx bxs-x-circle red"></i> Use with Multiple
                  LinkedIn Accounts
                </li>
              </ul>

              <div className="btn-box">
                <Link to="/profile-authentication" className="default-btn">
                  <i className="flaticon-tick"></i>
                  Start For Free
                  <span></span>
                </Link>
              </div>
            </div>
          </div>

          {/* <div className="col-lg-3 col-md-6">
            <div className="single-pricing-box">
              <div className="pricing-header">
                <h3>Basic</h3>
              </div>

              <div className="price">
                <sup>$</sup> 12 <sub>/ Per month</sub>
              </div>

              <ul className="pricing-features">
                <li>
                  <i className="bx bxs-badge-check"></i> Comment Reminder Tool
                </li>
                <li>
                  <i className="bx bxs-badge-check"></i> AI Comment Generator
                </li>
                <li>
                  <i className="bx bxs-badge-check"></i> 150 Comments / Month
                </li>
                <li>
                  <i className="bx bxs-badge-check"></i> 5 Comments / Day
                </li>
                <li>
                  <i className="bx bxs-x-circle red"></i> Set Comment Tone
                </li>
                <li>
                  <i className="bx bxs-x-circle red"></i> Use with Multiple
                  LinkedIn Accounts
                </li>
              </ul>

              <div className="btn-box">
                <Link
                  to="/paid-registration?level=basic"
                  className="default-btn"
                >
                  <i className="flaticon-tick"></i>
                  Select the Plan
                  <span></span>
                </Link>
              </div>
            </div>
          </div>
          */}

          <div className="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
            <div className="single-pricing-box">
              <div className="pricing-header">
                <h3>Growth</h3>
              </div>

              <div className="price">
                <sup>$</sup> 30 <sub> / Per month</sub>
              </div>

              <ul className="pricing-features">
                <li>
                  <i className="bx bxs-badge-check"></i> AI Comment Generator
                </li>
                <li>
                  <i className="bx bxs-badge-check"></i> 750 Comments / Month
                </li>
                <li>
                  <i className="bx bxs-badge-check"></i> 25 Comments / Day
                </li>
                <li>
                  <i className="bx bxs-badge-check"></i> Set Comment Tone
                </li>
                <li>
                  <i className="bx bxs-badge-check"></i> Customise Comment Type
                </li>
                <li>
                  <i className="bx bxs-x-circle red"></i> Use with Multiple
                  LinkedIn Accounts
                </li>
              </ul>

              <div className="btn-box">
                <Link
                  to="/paid-registration?level=growth"
                  className="default-btn"
                >
                  <i className="flaticon-tick"></i>
                  Boost Your Growth Today
                  <span></span>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
            <div className="single-pricing-box">
              <div className="pricing-header">
                <h3>Pro</h3>
              </div>

              <div className="price">
                <sup>$</sup> 99 <sub> / Per month</sub>
              </div>

              <ul className="pricing-features">
                <li>
                  <i className="bx bxs-badge-check"></i> AI Comment Generator
                </li>
                <li>
                  <i className="bx bxs-badge-check"></i> 3000 Comments / Month
                </li>
                <li>
                  <i className="bx bxs-badge-check"></i> 100 Comments / Day
                </li>
                <li>
                  <i className="bx bxs-badge-check"></i> Set Comment Tone
                </li>
                <li>
                  <i className="bx bxs-badge-check"></i> Customise Comment Type
                </li>
                <li>
                  <i className="bx bxs-badge-check"></i> Use with Multiple
                  LinkedIn Accounts
                </li>
              </ul>

              <div className="btn-box">
                <Link to="/paid-registration?level=pro" className="default-btn">
                  <i className="flaticon-tick"></i>
                  Boost a whole team!
                  <span></span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Pricing;
