import React from "react";
import { Link } from "gatsby";
import starIcon from "../../assets/images/star-icon.png";
import client1 from "../../assets/images/testimonials/client1.jpg";
import client2 from "../../assets/images/testimonials/client2.jpg";
import client3 from "../../assets/images/testimonials/client3.jpg";
import shape from "../../assets/images/shape/shape1.svg";
import Loadable from "@loadable/component";
const OwlCarousel = Loadable(() => import("react-owl-carousel3"));

const options = {
  loop: true,
  nav: true,
  dots: false,
  autoplayHoverPause: true,
  autoplay: true,
  margin: 30,
  navText: [
    "<i class='flaticon-left-1'></i>",
    "<i class='flaticon-right-1'></i>",
  ],
  responsive: {
    0: {
      items: 1,
    },
    768: {
      items: 2,
    },
    992: {
      items: 2,
    },
  },
};

const Testimonials = () => {
  const [display, setDisplay] = React.useState(false);

  React.useEffect(() => {
    setDisplay(true);
  }, []);

  return (
    <div className="testimonials-area pt-100 bg-black">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">
            <img src={starIcon} alt="testimonial" />
            Testimonials
          </span>
          <h2>What Our Clients are Saying?</h2>
          <p>
            How LinkedIn Powertools can help you to grow your business
            testimonial
          </p>
        </div>

        {display ? (
          <OwlCarousel
            className="testimonials-slides owl-carousel owl-theme"
            {...options}
          >
            <div className="single-testimonials-item">
              <p>
                I used not to comment on Linkedin because it can be super
                time-consuming. Now I’m commenting like a madman in English,
                Dutch and Polish, powered by Linkedin Powertool. It really helps
                foster relations
              </p>

              <div className="client-info">
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    src={
                      "https://media.licdn.com/dms/image/C4E03AQFbrRG3_Indbg/profile-displayphoto-shrink_800_800/0/1517682609281?e=1680134400&v=beta&t=lQZyob4Zz84s8o1CkGAmMOx6UATSDW-T3SvNKE6pucg"
                    }
                    alt="testimonial"
                  />
                  <div className="title">
                    <h3>Dawid Pacha</h3>
                    <span>Founder @TalksGroup</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="single-testimonials-item">
              <p>
                That's such a time-saver! I've always wanted to increase my
                visibility on LinkedIn, but I never had the time to comment on
                posts. Being able to use AI to do that for me is a total
                game-changer!
              </p>

              <div className="client-info">
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    src={
                      "https://veikko.fi/static/472e7df18f8999e774add7b4e3a54bf4/e7160/makinen-veikko-sq.webp"
                    }
                    alt="testimonial"
                  />
                  <div className="title">
                    <h3>Veikko Mäkinen</h3>
                    <span>Business Software Developer</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="single-testimonials-item">
              <p>
                I was tired of writing LinkedIn comments. Now I let AI write a
                draft comment based on the post.
              </p>
              <p>
                The browser plugin embed this feature right into LinkedIn
                interface.
              </p>
              <div className="client-info">
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    src={
                      "https://media.licdn.com/dms/image/C4E03AQFdzJSylI7Gmg/profile-displayphoto-shrink_100_100/0/1620036611129?e=1680134400&v=beta&t=nUjWPGjDTV8xpbKvXc6EEjtoLUTBQbGJK_hH_Yp5GQs"
                    }
                    alt="testimonial"
                  />
                  <div className="title">
                    <h3>José Kadlec</h3>
                    <span>Co-Founder at GoodCall, Recruitment Academy</span>
                  </div>
                </div>
              </div>
            </div>
          </OwlCarousel>
        ) : (
          ""
        )}
      </div>

      <div className="shape-img1">
        <img src={shape} alt="testimonial" />
      </div>
    </div>
  );
};

export default Testimonials;
